<template>
  <div>
    <nav class="bg-gray-50 text-center flex w-full justify-center items-center">
      <template v-for="men in filteredMenu" :key="men.id">
        <label
          :for="men.id"
          :style="men.category === activeCategory ? 
            { backgroundColor: '#EDFE86', color: '#1D2E48' } : 
            { color: '#7B8794' }"
          class="h-100 p-2 w-full font-semibold cursor-pointer"
          @click="onChangeFiltersThreads(men.category, men.text)"
        >
          {{ men.text }}
        </label>
      </template>
    </nav>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { menu } from "@/components/gamesVR/utils";

const props = defineProps({
  activeCategory: {
    type: String,
    required: true,
  },
  selectedTitle: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["changeFilter"]);

const filteredMenu = computed(() => {
  if (props.selectedTitle === 'tablet') {
    return menu.filter(item => item.text !== 'RhythmIQ');
  }
  return menu;
});

function onChangeFiltersThreads(category, text) {
  emit("changeFilter", { category, text });
}
</script>
