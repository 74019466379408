<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-7">
      <div class="w-100 flex flex-col justify-center">
        <div class="flex space-x-8">
          <h1
            :style="
              selectedTitle === 'vr'
                ? 'color: #EDFE86; border-bottom: 2px solid #EDFE86;'
                : 'color: #7B8794;'
            "
            class="text-lg font-semibold mb-6 pb-2 cursor-pointer"
            @click="selectTitle('vr')"
          >
            VR games
          </h1>
          <h1
            :style="
              selectedTitle === 'tablet'
                ? 'color: #EDFE86; border-bottom: 2px solid #EDFE86;'
                : 'color: #7B8794;'
            "
            class="text-lg font-semibold mb-6 pb-2 cursor-pointer"
            @click="selectTitle('tablet')"
          >
            Tablet games
          </h1>
        </div>
        <Menu
          @changeFilter="onChangeFiltersThreads"
          :activeCategory="currentCategory"
          :selectedTitle="selectedTitle"
        ></Menu>
      </div>
      <CardInformation :targets="selectedCategory" :title="title" />
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import Menu from "@/components/gamesVR/Menu.vue";
import CardInformation from "@/components/gamesVR/CardInformation.vue";
import { gamesVR, gamesTablet } from "@/components/gamesVR/utils";

const selectedCategory = ref(gamesVR);
const title = ref("");
const selectedTitle = ref("vr");
const currentCategory = ref("all");

function selectTitle(title) {
  selectedTitle.value = title;

  if (title === "vr") {
    selectedCategory.value = gamesVR;
  } else if (title === "tablet") {
    selectedCategory.value = gamesTablet;
  }

  currentCategory.value = "all";
  resetFilter();
}

function resetFilter() {
  const gamesList = selectedTitle.value === "vr" ? gamesVR : gamesTablet;
  selectedCategory.value = gamesList;
  title.value = "All";
}

function onChangeFiltersThreads({ category, text }) {
  currentCategory.value = category;
  const gamesList = selectedTitle.value === "vr" ? gamesVR : gamesTablet;

  if (category === "all") {
    selectedCategory.value = gamesList;
    title.value = "All";
  } else {
    selectedCategory.value = gamesList.filter(
      (game) => game.category === category
    );
    title.value = text;
  }
}
</script>
