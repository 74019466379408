const menu = [
  {
    text: "All",
    id: "1",
    category: "all",
  },
  {
    text: "Star Catcher",
    id: "2",
    category: "starcatcher",
  },
  {
    text: "Sun Blazer",
    id: "3",
    category: "sunblazer",
  },
  {
    text: "Moon Shadow",
    id: "4",
    category: "moonshadow",
  },
  {
    text: "RhythmIQ",
    id: "5",
    category: "rhythmiq",
  },
];

const gamesVR = [
  {
    number: "1",
    title: "Track",
    text: "Target Defense: Strike every approaching target with your paddles, ensuring none slip past you.",
    category: "starcatcher",
  },
  {
    number: "2",
    title: "Decide",
    text: "Color Strike: Hit only the targets of your assigned color with your paddles.",
    category: "starcatcher",
  },
  {
    number: "3",
    title: "Predict",
    text: "Path Prediction: Master the skill of anticipating the trajectory of incoming targets, ensuring you hit them all with either paddle.",
    category: "starcatcher",
  },
  {
    number: "4",
    title: "Switch",
    text: "Color-Match Coordination: Strike the correctly colored targets with the matching paddle, adapting to periodic changes in paddle assignments while focusing on the colors linked to each paddle.",
    category: "starcatcher",
  },
  {
    number: "5",
    title: "Listen",
    text: "Color-Switching Reflex: Hit only your assigned colored targets with either paddle, staying alert as your target colors periodically change.",
    category: "starcatcher",
  },
  {
    number: "6",
    title: "Order",
    text: "Sequential Target Strike: Aim to hit the colored targets in the exact order they appear at the top of the screen.",
    category: "starcatcher",
  },
  {
    number: "7",
    title: "Decide and Switch",
    text: "Focused Paddle: Strike the correct colored targets with the corresponding paddle, while skillfully ignoring periodic distractor targets that are not assigned to you.",
    category: "starcatcher",
  },
  {
    number: "8",
    title: "Switch and Decide",
    text: "Adaptive Color Strike: Hit your designated colored targets with either paddle as they are launched towards you, adapting to changing color assignments and skillfully avoiding distractor targets not meant for you.",
    category: "starcatcher",
  },
  {
    number: "9",
    title: "Listen and Switch",
    text: "Color Assignment Agility: Strike only the targets of colors assigned to you as they are shot towards you, staying nimble as your color assignments change periodically.",
    category: "starcatcher",
  },
  {
    number: "10",
    title: "Predict and Decide",
    text: "Trajectory Prediction: Hone your skill in predicting the path of targets shot towards you, ensuring you hit only the assigned colors.",
    category: "starcatcher",
  },
  {
    number: "11",
    title: "Predict and Switch",
    text: "Predictive Paddle: Master the art of anticipating target trajectories after they're shot towards you, striking the assigned colors with the correct paddle, which will switch colors throughout the game.",
    category: "starcatcher",
  },
  {
    number: "12",
    title: "Predict and Order",
    text: "Path & Order: Excel in predicting the flight path of targets launched towards you, hitting the assigned colors with either paddle in the sequence they are displayed.",
    category: "starcatcher",
  },
  {
    number: "13",
    title: "Order Decide and Switch",
    text: "Color Sequence: Strike the targets in the precise color order using the correctly assigned paddle.",
    category: "starcatcher",
  },
  {
    number: "14",
    title: "Listen and Order",
    text: "Audio-Guided Color Sequence: Follow the audio cues to hit targets in the correct color order.",
    category: "starcatcher",
  },
  {
    number: "15",
    title: "AIM",
    text: "Green Target Blitz: Aim to quickly identify and shoot all the green targets as they appear.",
    category: "sunblazer",
  },
  {
    number: "16",
    title: "Divert",
    text: "Green vs. Orange: Rapidly hit all standard (green) targets while skillfully avoiding the trap (orange) targets.",
    category: "sunblazer",
  },
  {
    number: "17",
    title: "Time",
    text: "Dynamic Green Targets: Aim to swiftly shoot green targets, with a twist – some lose value over time, and others morph from standard to trap targets (turning orange).",
    category: "sunblazer",
  },
  {
    number: "18",
    title: "Guard",
    text: "Rapid Fire: Shoot targets as fast as possible, including standard and diminishing ones that lose value over time, while navigating around blockers that attempt to thwart your aim.",
    category: "sunblazer",
  },
  {
    number: "19",
    title: "Target",
    text: "Target and Turret Duel: Shoot targets as quickly as you can while facing off against randomly appearing turrets (floating orbs); destroy them or block their fireballs with your shield.",
    category: "sunblazer",
  },
  {
    number: "20",
    title: "In Order",
    text: "Numerical Order: Challenge yourself to shoot the targets in precise numerical order.",
    category: "sunblazer",
  },
  {
    number: "21",
    title: "Symbol",
    text: "Shape-Shifting: Aim to shoot targets matching the designated shapes displayed at the top, adapting as the shapes periodically change, utilizing either hand.",
    category: "sunblazer",
  },
  {
    number: "22",
    title: "Listen",
    text: "Verbal Shape: Focus on shooting targets with shapes verbally communicated to you, staying alert as these shapes change periodically, using either hand.",
    category: "sunblazer",
  },
  {
    number: "23",
    title: "Symbol Change",
    text: "Hand-Specific Shape: Strike targets with shapes using the assigned hands as indicated at the top, adapting to periodically changing shapes.",
    category: "sunblazer",
  },
  {
    number: "24",
    title: "Sequence",
    text: "Memory Sequence: Memorize the sequence in which targets turn green and then accurately shoot them in that exact order.",
    category: "sunblazer",
  },
  {
    number: "25",
    title: "Defend",
    text: "Quickfire and Defense: Rapidly shoot targets while avoiding trap targets, and confront randomly appearing turrets (floating orbs) – either shoot them to destroy or use your shield to block their fireballs.",
    category: "sunblazer",
  },
  {
    number: "26",
    title: "Detect",
    text: "Memory Test: Memorize the identities of targets as they briefly flash as either standard or trap targets, then revert to look alike. Shoot only those that were identified as standard targets.",
    category: "sunblazer",
  },
  {
    number: "27",
    title: "Pattern",
    text: "Pattern Replication: Memorize a displayed pattern with cues and accurately repeat the patterns.",
    category: "moonshadow",
  },
  {
    number: "28",
    title: "Series",
    text: "Order-Based Pattern: Memorize a specific pattern displayed with cues and precisely repeat it in the same order.",
    category: "moonshadow",
  },
  {
    number: "29",
    title: "Reverse Series",
    text: "Reverse Pattern Recall: Memorize a given pattern (with cues) in a specific order, then challenge your brain to repeat it in reverse order.",
    category: "moonshadow",
  },
  {
    number: "30",
    title: "Match",
    text: "Color-Coded Pattern: Remember a displayed pattern with specific colors and cues, then replicate it using the correct hand assigned to each color.",
    category: "moonshadow",
  },
  {
    number: "31",
    title: "Shape or Color",
    text: "Shape and Color: Memorize a pattern featuring specific colors and shapes, then repeat it by accurately hitting only the targets with the assigned shape or color.",
    category: "moonshadow",
  },
  {
    number: "32",
    title: "Rhythm",
    text: "Rhythmic Pattern: Commit to memory a pattern displayed with cues and a musical beat, then replicate the pattern matching the same rhythm and timing.",
    category: "moonshadow",
  },
  {
    number: "33",
    title: "Shapes",
    text: "Selective Shape: Remember a displayed pattern that includes multiple shapes with cues, and then accurately repeat the pattern by hitting only the targets with your assigned shape.",
    category: "moonshadow",
  },
  {
    number: "34",
    title: "Random Match",
    text: "Color-Hand Coordination: Learn and replicate a pattern displayed with specific colors and cues, using the correct hand assigned to each color, while adapting to changes in hand assignments during each sequence.",
    category: "moonshadow",
  },
  {
    number: "35",
    title: "Number Order",
    text: "Numbered Pattern: Memorize a displayed pattern that includes a set of numbers, and then accurately repeat the pattern following the numerical order displayed.",
    category: "moonshadow",
  },
  {
    number: "36",
    title: "Rhythm Match",
    text: "Rhythmic Color: Commit to memory a pattern with specific colors and a musical beat, then replicate it using the correct hand for each color, all while maintaining the rhythm.",
    category: "moonshadow",
  },
  {
    number: "37",
    title: "Shape or Color Match",
    text: "Color and Shape: Remember a displayed pattern with specific colors and shapes, and repeat it by accurately striking only the assigned shape or color, using the correct hand for each color.",
    category: "moonshadow",
  },
  {
    number: "38",
    title: "Rhythm Series",
    text: "Musical Pattern: Memorize a displayed pattern in a specific order along with its accompanying musical beat, then replicate the pattern precisely in the same order and rhythm.",
    category: "moonshadow",
  },
  {
    number: "39",
    title: "RhythmIQ",
    text: "Rhythm And Timing: Focus on enhancing your rhythm, timing, focus, and coordination, immersing yourself in a training game designed to fine-tune these essential skills.",
    category: "rhythmiq",
  },
];

const gamesTablet = [
  {
    number: "1",
    title: "Track – Target Defense",
    text: "Strike every approaching target, ensuring none slip past you.",
    category: "starcatcher",
  },
  {
    number: "2",
    title: "Decide – Color Strike",
    text: "Tap only the targets of your assigned color.",
    category: "starcatcher",
  },
  {
    number: "3",
    title: "Listen – Color-Switching Reflex",
    text: "Tap only your assigned colored targets, staying alert as your target colors periodically change via audio cues.",
    category: "starcatcher",
  },
  {
    number: "4",
    title: "Order – Sequential Target Strike",
    text: "Aim to tap the colored targets in the exact order they appear at the top of the screen.",
    category: "starcatcher",
  },
  {
    number: "5",
    title: "Switch & Decide – Adaptive Color Strike",
    text: "Tap your designated color and shape targets as they are launched towards you, adapting to changing assignments and skillfully avoiding distractor targets not meant for you.",
    category: "starcatcher",
  },
  {
    number: "6",
    title: "Listen & Order – Audio-Guided Color Sequence",
    text: "Follow the audio cues to tap targets in the correct color order.",
    category: "starcatcher",
  },
  {
    number: "7",
    title: "Time – Dynamic Green Targets",
    text: "Aim to swiftly tap green targets, with a twist – some lose value over time, and others morph from standard to trap targets (turning orange).",
    category: "sunblazer",
  },
  {
    number: "8",
    title: "Symbol Change – Hand-Specific Shape",
    text: "Strike targets with shapes using the assigned hands as indicated at the top, adapting to periodically changing shapes.",
    category: "sunblazer",
  },
  {
    number: "9",
    title: "Detect – Memory Test",
    text: "Memorize the identities of targets as they briefly flash as either standard or trap targets, then revert to look alike. Tap only those that were identified as standard targets.",
    category: "sunblazer",
  },
  {
    number: "10",
    title: "Hear – Verbal Shape",
    text: "Focus on tapping targets with shapes verbally communicated to you, staying alert as these shapes change periodically.",
    category: "sunblazer",
  },
  {
    number: "11",
    title: "Shape – Selective Shape",
    text: "Remember a displayed pattern that includes multiple shapes with cues, and then accurately repeat the pattern by tapping only the targets with your assigned shape.",
    category: "moonshadow",
  },
  {
    number: "12",
    title: "Shape or Color – Shape and Color",
    text: "Memorize a pattern featuring specific colors and shapes, then repeat it by accurately tapping only the targets with the assigned shape or color.",
    category: "moonshadow",
  },
  {
    number: "13",
    title: "Reverse Series – Reverse Pattern Recall",
    text: "Memorize a given pattern (with cues) in a specific order, then challenge your brain to repeat it in reverse order.",
    category: "moonshadow",
  },
  {
    number: "14",
    title: "Number Order – Numbered Pattern",
    text: "Memorize a displayed pattern that includes a set of numbers, and then accurately repeat the pattern following the numerical order displayed.",
    category: "moonshadow",
  },
]

export { menu, gamesVR, gamesTablet };
